const SolanaIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			// fillRule="evenodd"
			// clipRule="evenodd"
			d="M12 2C6.47724 2 2 6.47724 2 12C2 17.5228 6.47724 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47724 17.5228 2 12 2ZM7.89462 6.83641C7.96469 6.75145 8.04138 6.71338 8.15283 6.71393C11.4339 6.71779 14.7156 6.7189 17.9972 6.72166C18.0116 6.72166 18.0259 6.73655 18.0596 6.75476C17.4897 7.4411 16.8921 8.09986 16.3686 8.81324C16.0524 9.24359 15.6966 9.35007 15.1906 9.34621C12.1843 9.32524 9.17793 9.33793 6.17159 9.33903C6.07228 9.33903 5.97352 9.33903 5.81186 9.33903C6.53572 8.47062 7.2171 7.65462 7.89572 6.83641H7.89462ZM16.032 17.1559C15.9531 17.2513 15.8737 17.2866 15.7534 17.2866C12.48 17.2839 9.20662 17.2844 5.93324 17.2833C5.90786 17.2833 5.88303 17.2745 5.82069 17.2629C6.07779 16.9528 6.32 16.6593 6.56331 16.3674C7.00303 15.8394 7.44552 15.3131 7.88248 14.7823C7.95145 14.699 8.01766 14.651 8.13297 14.651C11.4246 14.6543 14.7167 14.6543 18.0083 14.6554C18.0237 14.6554 18.0397 14.6665 18.0905 14.6858C17.392 15.5222 16.709 16.3371 16.032 17.1564V17.1559ZM17.7948 13.317C14.6234 13.317 11.4527 13.3148 8.28138 13.3214C8.06952 13.3214 7.92993 13.2601 7.79586 13.0963C7.14538 12.3029 6.48331 11.5189 5.82676 10.7332C5.88414 10.6378 5.96579 10.6759 6.03034 10.6759C9.25628 10.6753 12.4828 10.6775 15.7087 10.6759C15.8566 10.6759 15.9586 10.7112 16.0585 10.8331C16.7266 11.6474 17.4047 12.4541 18.1247 13.3164H17.7953L17.7948 13.317Z"
			fill="currentColor"
		/>
	</svg>
)

export default SolanaIcon
