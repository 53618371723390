const BaseIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			// fillRule="evenodd"
			// clipRule="evenodd"
			d="M11.5839 2C11.8506 2 12.1173 2 12.384 2C12.418 2.00448 12.452 2.01185 12.4861 2.01305C12.8538 2.02602 13.2182 2.06861 13.5816 2.12545C14.0949 2.20575 14.5987 2.32519 15.0916 2.48819C17.0236 3.12712 18.6278 4.24128 19.8804 5.8441C21.1791 7.50584 21.8903 9.39366 21.9872 11.5063C21.9886 11.5375 21.9957 11.5684 22.0001 11.5995V12.3994C21.9955 12.4331 21.989 12.4667 21.9868 12.5006C21.9755 12.6707 21.9693 12.8412 21.954 13.011C21.9087 13.5134 21.8172 14.0081 21.6923 14.4965C20.9758 17.2987 19.0205 19.6973 16.4161 20.9739C15.1257 21.6064 13.7617 21.9422 12.3274 21.9982C11.6754 22.0238 11.0283 21.974 10.3844 21.8729C9.88682 21.7947 9.39815 21.6795 8.91957 21.523C6.82872 20.8396 5.13407 19.6072 3.84459 17.8274C2.80962 16.3987 2.21007 14.7985 2.01929 13.045C2.01217 12.9796 2.00713 12.9139 2.00016 12.838H15.2313C15.2492 12.7541 15.2474 11.23 15.2315 11.17C15.229 11.169 15.2265 11.168 15.224 11.1672C15.2215 11.1663 15.2189 11.1654 15.2164 11.1649C15.2137 11.1644 15.2111 11.1644 15.2084 11.1643C15.2058 11.1641 15.2032 11.1639 15.2004 11.1639C15.1951 11.1637 15.1898 11.1637 15.1844 11.1636C15.1818 11.1636 15.1791 11.1636 15.1764 11.1636C10.8137 11.1636 6.45109 11.1636 2.08838 11.1636C2.08302 11.1636 2.07774 11.1636 2.07237 11.1636C2.06701 11.1636 2.06172 11.1636 2.05636 11.1636C2.05372 11.1636 2.051 11.1636 2.04835 11.1636C2.04571 11.1636 2.04299 11.1635 2.04035 11.1634C2.03771 11.1633 2.03498 11.1633 2.03234 11.163C2.0297 11.1628 2.02706 11.1622 2.02442 11.1616C2.02186 11.1611 2.01921 11.1606 2.01681 11.1596C2.01441 11.1586 2.01209 11.1571 2.01001 11.1556C2.00793 11.154 2.00616 11.1522 2 11.1467C2.00937 11.0629 2.01961 10.9762 2.02858 10.8894C2.07653 10.4276 2.15867 9.9716 2.2762 9.5228C2.81946 7.44788 3.90744 5.7116 5.55189 4.33702C7.27063 2.90024 9.25773 2.12729 11.4979 2.01257C11.5267 2.01113 11.5553 2.00432 11.5839 2Z"
			fill="currentColor"
		/>
	</svg>
)

export default BaseIcon
