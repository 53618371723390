const EthereumIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.0005 2L18 11.7116L12.0005 15.1707L6 11.7116L12.0005 2ZM11.9987 17L18 13.25L11.9987 22L6.0009 13.25L11.9987 17Z"
			fill="currentColor"
		/>
	</svg>
)

export default EthereumIcon
